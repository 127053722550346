import React from "react";
import { navigate } from "gatsby";
import { Auth } from "@aws-amplify/auth";

import Button from "../../atomes/button";

const SigninBtn = ({ errors, username, password }) => {
  const handelSignin = e => {
    e.preventDefault();
    Auth.signIn(username, password)
      .then(user => {
        navigate("/App/thankyou/");
      })
      .catch(err => console.error(err));
  };

  const handelChangePassword = e => {
    e.preventDefault();
    // todo impliment change password
  };
  return (
    <>
      <Button type="secondary" handelClick={handelSignin}>
        سجل الدخول
      </Button>
      <Button type="link" handelClick={handelChangePassword}>
        تغيير كلمة المرور
      </Button>
    </>
  );
};

export default SigninBtn;
