import React from "react";

import Login from "../components/organism/login";
import SigninBtn from "../components/molecules/signinBtn";

const Signin = () => {
  return <Login Buttons={SigninBtn} initFromState="signin" />;
};

export default Signin;
