import React, { useState } from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery, navigate } from "gatsby";

import { Button, NewSelect } from "../../atomes";
import { subjects } from "../../../utility/const";
import { getLevels, getDivisions } from "../../../utility/helper";
import LoginWithProvider from "./LoginWithProvider";

const imageQuery = graphql`
  query {
    classroom: allImageSharp(
      filter: { fluid: { originalName: { eq: "classroom.png" } } }
    ) {
      nodes {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo: allImageSharp(
      filter: { fluid: { originalName: { eq: "logo.png" } } }
    ) {
      nodes {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Login = ({ Buttons, initFromState, location }) => {
  const errorsMessages = {
    emptyPassword: "إملء خانةالكلمة السرية من فضلك",
    passwordLength: "يجب أن يكون عدد حروف الكلمة السرية أكبر أو يساوي 8",
    emptyEmail: "إملء خانةالبريد الإلكتروني من فضلك",
    correctEmailTemplate:
      "يجب أن يكون بريدك الإلكتروني من الشكل example@gmail.com",
  };

  const [values, setValues] = useState({
    subject: "إختر المادة",
    level: "إختر المستوى",
    division: "إختر الشعبة",
    type: "completeRegistration",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState(Object.values(errorsMessages));
  const [formState, setFormState] = useState(initFromState);
  const {
    classroom: {
      nodes: [{ fluid: classroomImg }],
    },
    logo: {
      nodes: [{ fluid: logoImg }],
    },
  } = useStaticQuery(imageQuery);

  const emailValidation = value => {
    const { emptyEmail, correctEmailTemplate } = errorsMessages;
    if (value === "") {
      if (errors.includes(emptyEmail)) return false;
      setErrors(state => [...state, emptyEmail]);
      return false;
    } else {
      setErrors(errors.filter(error => error !== emptyEmail));
    }
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      ) === false
    ) {
      if (errors.includes(correctEmailTemplate)) return false;
      setErrors(state => [...state, correctEmailTemplate]);
      return false;
    } else {
      setErrors(errors.filter(error => error !== correctEmailTemplate));
    }

    return false;
  };
  const passwordValidation = value => {
    const { emptyPassword, passwordLength } = errorsMessages;
    if (value === "") {
      if (errors.includes(emptyPassword)) return false;
      setErrors(state => [...state, emptyPassword]);
      return false;
    } else {
      setErrors(errors.filter(error => error !== emptyPassword));
    }
    if (value.length < 6) {
      if (errors.includes(passwordLength)) return false;
      setErrors(state => [...state, passwordLength]);
      return false;
    } else {
      setErrors(errors.filter(error => error !== passwordLength));
    }
    return false;
  };
  const handleEmailChange = e => {
    e.preventDefault();
    const value = e.target.value;
    emailValidation(value);
    setEmail(value);
  };

  const handelPasswordChange = e => {
    e.preventDefault();
    const value = e.target.value;
    passwordValidation(value);
    setPassword(value);
  };

  const handelCodeChange = e => {
    e.preventDefault();
    const value = e.target.value;
    setCode(value);
  };

  const handleUsernameChange = e => {
    e.preventDefault();
    const value = e.target.value;
    setUsername(value);
  };

  const handelPreSignup = type => e => {
    e.preventDefault();
    setFormState(type === "user" || type === "parent" ? "signup" : type);
    setValues(state => ({ ...state, type }));
  };

  const setValuesAndForm = fn => {
    setValues(state => fn(state));
    setFormState("signup");
  };

  return (
    <div className="login">
      <div className="login-logo">
        <Img fluid={logoImg} alt="logo" />
      </div>
      <div className="login-image">
        <Img style={{ height: "100vh" }} fluid={classroomImg} alt="قسم" />
      </div>
      <div className="login-form">
        {location?.hash === "#tuto" && (
          <h3> عليك فتح حساب في الموقع قبل التسجيل في الدروس التدعيمية </h3>
        )}
        <form>
          {formState === "preSignup" && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3> هل أنت: </h3>
              <Button handelClick={handelPreSignup("teacher")}>أستاذ</Button>
              <Button handelClick={handelPreSignup("student")}>تلميذ</Button>
              <Button handelClick={handelPreSignup("parent")}>ولي أمر</Button>
              <Button handelClick={handelPreSignup("user")}>مستخدم</Button>
            </div>
          )}
          {formState === "teacher" && (
            <>
              <h3>إختر المادة</h3>
              <NewSelect
                name="subject"
                value={values.subject}
                options={["إختر المادة", ...subjects]}
                setValue={setValuesAndForm}
              />
            </>
          )}
          {formState === "student" && (
            <>
              <NewSelect
                value={values.level}
                options={["إختر المستوى", ...getLevels()]}
                setValue={setValues}
                name="level"
              />
              <NewSelect
                value={values.division}
                options={["إختر الشعبة", ...getDivisions(values.level)]}
                setValue={setValuesAndForm}
                name="division"
              />
            </>
          )}
          {formState === "signup" && (
            <input
              style={{ marginBottom: "1em" }}
              id="username"
              type="text"
              placeholder="اسم المستخدم"
              value={username}
              onChange={handleUsernameChange}
            />
          )}
          {formState.includes("sign") && (
            <>
              <LoginWithProvider />
              <input
                style={{ marginBottom: "1em" }}
                id="email"
                type="email"
                placeholder="example@gmail.com"
                value={email}
                onChange={handleEmailChange}
              />
              <input
                style={{ marginBottom: "1em" }}
                id="password"
                type="password"
                placeholder="كلمة المرور"
                value={password}
                onChange={handelPasswordChange}
              />
              <ul>
                {errors.map((error, index) => (
                  <li key={index} style={{ color: "#191b1d" }}>
                    {error}
                  </li>
                ))}
              </ul>
            </>
          )}
          {formState === "confirmation" && (
            <input
              style={{ marginBottom: "1em" }}
              id="code"
              type="number"
              placeholder="رمز التحقق"
              value={code}
              onChange={handelCodeChange}
            />
          )}

          {formState.includes("sign") && (
            <>
              <Buttons
                errors={errors}
                username={username || email}
                email={email}
                password={password}
                code={code}
                values={values}
                formState={formState}
                setFormState={setFormState}
              />
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
